import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import ApiHeading from '../components/ApiHeading'
import MissingProperty from '../components/MissingProperty'
import Seo from '../components/Seo'
import { PAGE_VIEWED } from '../constants/metrics'
import MetricsService from '../services/metricsService'
import { AppState } from '../state/store'
import { getExpandTryOutState } from '../state/tryOut'
import { linkRenderer } from '../utils/markdown.utils'
import DocumentationLayout from './DocumentationLayout'

export interface IApiLayoutProps {
  apiName: string
  resourceName: string
  description?: string
  endpoint?: string
  badgeType?: string
  leftSideContents: React.ReactNode
  rightSideContents: React.ReactNode
  versions: number[]
  currentVersion: number
}

const ApiLayout: React.FC<IApiLayoutProps> = ({
  apiName,
  resourceName,
  description,
  endpoint,
  badgeType,
  leftSideContents,
  rightSideContents,
  versions,
  currentVersion,
}): JSX.Element => {
  const metricsService = MetricsService.getInstance()
  const hideDocumentation = useSelector((state: AppState) =>
    getExpandTryOutState(state)
  )

  metricsService.track(PAGE_VIEWED, {
    pageName: resourceName,
    subPageName: apiName,
  })

  return (
    <DocumentationLayout isApiLayout>
      <Seo title={apiName} description={description} />
      <article className="api-documentation-container">
        <section className={!hideDocumentation ? 'left-pane' : 'hide'}>
          <ApiHeading
            name={apiName}
            resourceName={resourceName}
            endpoint={endpoint}
            badgeType={badgeType}
            versions={versions}
            currentVersion={currentVersion}
          />
          {description ? (
            <ReactMarkdown
              source={description}
              className="description"
              renderers={linkRenderer}
            />
          ) : (
            <MissingProperty name="api description" />
          )}
          {leftSideContents}
        </section>
        <section className={`right-pane ${hideDocumentation && 'expand'}`}>
          {rightSideContents}
        </section>
      </article>
    </DocumentationLayout>
  )
}

export default ApiLayout
